<template>
  <div>
    <b-card>
      <TitleTable titleTable="Facility Stock" iconTable="GitBranchIcon" />
      <div class="m-1">
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("Show") }}</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("Entries") }}</label>
          </b-col>
          <!-- {{ $t("Search") }} -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="mr-2" />
            </div>
          </b-col>
        </b-row>
      </div>
      <LoadingTable v-if="items === null" />
      <b-table
        v-else
        :items="items"
        :fields="fields"
        selectable
        selected-variant="danger"
        select-mode="single"
        responsive
        bordered
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >
        <template #thead-top="">
          <b-tr>
            <b-th colspan="1" variant="success" class="text-center">Item</b-th>
            <b-th colspan="5" variant="success" class="text-center"
              >Cost Price & Retail</b-th
            >
            <b-th colspan="3" variant="info" class="text-center"
              >Stock Base</b-th
            >
            <b-th colspan="4" variant="warning" class="text-center"
              >Inventory Injection</b-th
            >
            <b-th colspan="3" variant="primary" class="text-center"
              >Supply Order Injection</b-th
            >
          </b-tr>
        </template>
        <!-- cambiar el width de retailMoneyInStock -->
        <template #head(retailMoneyInStock)="data">
          <div class="text-center width-header">{{ data.label }}</div>
        </template>
        <template #head(doseCostInStock)="data">
          <div class="text-center width-header">{{ data.label }}</div>
        </template>
        <template #cell(status)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.status ? $t("Active") : $t("Inactive")
            }}</span>
          </div>
        </template>
        <template #cell(unitRetailPrice)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize"
              >$ {{ data.item.unitRetailPrice }}</span
            >
          </div>
        </template>
        <template #cell(itemDoses)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize"
              >$ {{ data.item.itemDoses }}</span
            >
          </div>
        </template>
        <template #cell(itemSalePriceDose)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize"
              >$ {{ data.item.itemSalePriceDose }}</span
            >
          </div>
        </template>
        <template #cell(costBase)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize"
              >$ {{ data.item.costBase }}</span
            >
          </div>
        </template>
        <template #cell(doseCostInStock)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize"
              >$ {{ data.item.doseCostInStock }}</span
            >
          </div>
        </template>
        <template #cell(retailMoneyInStock)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize"
              >$ {{ data.item.retailMoneyInStock }}</span
            >
          </div>
        </template>
        <template #cell(supplyOrderCost)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize"
              >$ {{ data.item.supplyOrderCost }}</span
            >
          </div>
        </template>
        <template #cell(profitInStock)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize"
              >$ {{ data.item.profitInStock }}</span
            >
          </div>
        </template>
        <!-- <template #cell(actions)="data">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-danger"
            class="btn-icon"
            v-b-tooltip.hover.top="'Delete'"
            v-if="$can('destroy', 'module_360crm.crm_admin.card_status')"
            @click="deleteOrder(data.item)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </template> -->
      </b-table>
      <PaginationTable
        :dataMeta="dataMeta"
        :totalUsers="totalUsers"
        :perPage="perPage"
        :currentPage="currentPage"
        @page-changed="pageChanged"
      />
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BSkeletonTable,
  BBadge,
  BAvatar,
  BAlert,
  BButton,
  VBTooltip,
  BTh,
  BTr,
} from "bootstrap-vue";
import axiosClinicStock from "@/services/admin/inventory/manager";

import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

import ToastNotification from "@/components/ToastNotification";
import SpinnerLoading from "@/components/SpinnerLoading";
import PaginationTable from "@/components/PaginationTable";
import TitleTable from "@/components/TitleTable.vue";
import LoadingTable from "@/components/LoadingTable.vue";
import GoBack from "@/components/ButtonBack.vue";

import { notificationCheck } from "@/mixins/NotificationSetup";

import { ref } from "@vue/composition-api";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BSkeletonTable,
    BBadge,
    BAvatar,
    BButton,
    BAlert,
    BTh,
    BTr,
    ToastNotification,
    SpinnerLoading,
    PaginationTable,
    TitleTable,
    LoadingTable,
    GoBack,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "Name",
          sortable: true,
          class: "table-success",
          thStyle: {
            backgroundColor: "#afeaca",
          },
        },
        {
          key: "users.name",
          label: "Presentation",
          sortable: true,
          class: "table-success",
          thStyle: {
            backgroundColor: "#afeaca",
          },
        },
        {
          key: "itemDoses",
          label: "Presentation Dose",
          sortable: true,
          class: "table-success",
          thStyle: {
            backgroundColor: "#afeaca",
          },
        },
        {
          key: "itemRetailDose",
          label: "Dose",
          class: "table-success",
          thStyle: {
            backgroundColor: "#afeaca",
          },
        },
        {
          key: "unitRetailPrice",
          label: "Box / Jar",
          class: "table-success",
          thStyle: {
            backgroundColor: "#afeaca",
          },
        },
        {
          key: "itemSalePriceDose",
          label: "Retail Price",
          class: "table-success",
          thStyle: {
            backgroundColor: "#afeaca",
          },
        },
        {
          key: "stockDoseBase",
          label: "Dose",
          class: "table-info",
          thStyle: {
            backgroundColor: "#b8f2f9",
          },
        },
        {
          key: "stockUnitBase",
          label: "Box / Jar",
          class: "table-info",
          thStyle: {
            backgroundColor: "#b8f2f9",
          },
        },
        {
          key: "costBase",
          label: "Order Cost",
          class: "table-info",
          thStyle: {
            backgroundColor: "#b8f2f9",
          },
        },
        {
          key: "doseInStock",
          label: "Dose ",
          class: "table-warning ml-10",
          thStyle: {
            backgroundColor: "#ffe4ca",
          },
        },
        {
          key: "doseCostInStock",
          label: "Money Cost By Dose In Stock",
          class: "table-warning",
          thStyle: {
            backgroundColor: "#ffe4ca",
          },
        },
        {
          key: "retailMoneyInStock",
          label: "Retail Money in Stock",
          class: "table-warning",
          thStyle: {
            backgroundColor: "#ffe4ca",
          },
        },
        {
          key: "profitInStock",
          label: "Profit In Stock",
          class: "table-warning",
          thStyle: {
            backgroundColor: "#ffe4ca",
          },
        },
        {
          key: "supplyDose",
          label: "Dose",
          class: "table-primary",
          thStyle: {
            backgroundColor: "#cfdceb",
          },
        },
        {
          key: "supplyUnits",
          label: "Box/jar",
          class: "table-primary",
          thStyle: {
            backgroundColor: "#cfdceb",
          },
        },
        {
          key: "supplyOrderCost",
          label: "Order cost",
          class: "table-primary",
          thStyle: {
            backgroundColor: "#cfdceb",
          },
        },
      ],
      items: null,
      loading: false,
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      perPage: 10,
      currentPage: {
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100],
      filtro: false,
    };
  },
  mounted() {
    this.getOrder();
  },
  watch: {
    perPage(newVal) {
      if (!this.filtro) {
        this.getOrder();
      }
    },
    "$variableGlobal.facility": function (value) {
      this.getOrder();
      this.$refs.toast.success("Facility has been changed successfully");
    },
  },
  methods: {
    getOrder() {
      axiosClinicStock
        .facilityList(this.perPage)
        .then(
          ({ registro: { data, current_page, total, per_page, ...res } }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          }
        );
    },
    deleteOrder(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axiosClinicStock.orderDelete(item.id).then((response) => {
            this.$refs.toast.success(
              "Order type has been deleted successfully"
            );
            this.pageChanged(this.currentPage.page);
          });
        }
      });
    },
    pageChanged(page) {
      if (!this.filtro) {
        axiosClinicStock
          .facilityPagination(this.perPage, page)
          .then(({ registro: { data, total, current_page, ...res } }) => {
            // pasar objetos de objetos a array de objetos para que funcione el b-table
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
table .table-success {
  background-color: #afeaca !important;
}
table > thead tr th .table-success {
  background-color: #afeaca !important;
}
table .table-warning {
  background-color: #ffe4ca !important;
}
table > thead tr th .table-warning {
  background-color: #ffe4ca !important;
}
table .table-primary {
  background-color: #cfdceb !important;
}
table > thead tr th .table-primary {
  background-color: #cfdceb !important;
}
table .table-info {
  background-color: #b8f2f9 !important;
}
table > thead tr th .table-info {
  background-color: #b8f2f9 !important;
}
.width-header {
  width: 100px !important;
}
</style>
