import { userAxios } from '@/services'

const facilityList = async (perPage) => {
    try {
        return await userAxios.get(`inventory-manager/facility/inventory/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const facilityPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`inventory-manager/facility/inventory/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    facilityList,
    facilityPagination
}